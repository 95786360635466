import { createSlice } from "@reduxjs/toolkit";
import {callApi} from '../Helper/helper'
import { apiFail,apiSuccess } from './mainSlice'
const settingSlice = createSlice({
    name:"Setting",
    initialState:{
        main:{
            _id:null,
            mainLogo:null,
            smallLogo:null
        },
    },
    reducers:{
        setMainSetting:(state,action)=>{
            state.main=action.payload
        },
    }
})
export default settingSlice.reducer
const {setMainSetting} = settingSlice.actions
export const getMainSetting = () => async dispatch =>{
    try {
        let res = await callApi('post','/setting/main/list',{})
        dispatch(setMainSetting(res.data.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const updateMainSetting = (value) => async dispatch => {
    try {
        let res = await callApi('post','/setting/main/update',value,{'Content-Type': 'multipart/form-data'})
        dispatch(apiSuccess(res.data))
        getMainSetting()
    } catch (error) {
        dispatch(apiFail(error))
    }
}