import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
const Login = lazy(() => import('./auth/Login'));
const Forgot = lazy(() => import('./auth/password/forgot'));
const Reset = lazy(() => import('./auth/password/reset'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Profile = lazy(() => import('./auth/Profile'));
const CmsList = lazy(()=>import('./cms/list'))
const CmsEdit = lazy(()=>import('./cms/edit'))
const SettingMain = lazy(()=>import('./setting/main'))
// Invester component start
const InvesterList = lazy(()=>import('./Invester/list'))
const InvesterAdd = lazy(()=>import('./Invester/add'))
const InvesterEdit = lazy(()=>import('./Invester/edit'))
const InvesterView = lazy(()=>import('./Invester/view'))
// Invester component end
// Testimonial component start
const TestimonialList = lazy(()=>import('./Testimonial/list'))
const TestimonialAdd = lazy(()=>import('./Testimonial/add'))
const TestimonialEdit = lazy(()=>import('./Testimonial/edit'))
// Testimonial component end
// Gold bar component start
const GoldBarList = lazy(()=>import('./GoldBar/list'))
const GoldBarAdd = lazy(()=>import('./GoldBar/add'))
const GoldBarEdit = lazy(()=>import('./GoldBar/edit'))
// Gold bar component end


const RequestList = lazy(()=>import('./Request/list'))
/* theme default component */
const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));
const BasicElements = lazy(() => import('./form-elements/BasicElements'));
const BasicTable = lazy(() => import('./tables/BasicTable'));
const Mdi = lazy(() => import('./icons/Mdi'));
const ChartJs = lazy(() => import('./charts/ChartJs'));
const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          {/* auth route start */}
            <Route exact path="/login" component={ Login } />
            <Route exact path="/password/forgot" component={ Forgot } />
            <Route path="/password/reset" component={ Reset } />
            <Route exact path="/" component={ Dashboard } />
            <Route exact path="/profile" component={ Profile } />
          {/* auth route end */}
          {/* CMS Route start */}
            <Route exact path ="/cms/list" component={CmsList} />
            <Route path="/cms/:slug" component={CmsEdit} />
          {/* CMS Route end */}
          {/* Setting Route start */}
            <Route exact path="/setting/main" component={SettingMain} />
          {/* Setting Route start */}
          {/* Invester route start */}
            <Route exact path="/investor/list" component={InvesterList} />
            <Route exact path="/investor/add" component={InvesterAdd} />
            <Route path="/investor/edit/:id" component={InvesterEdit} />
            <Route path="/investor/view/:id" component={InvesterView} />
          {/* Invester route end */}
          {/* Testimonial route start */}
            <Route exact path="/testimonial/list" component={TestimonialList} />
            <Route exact path="/testimonial/add" component={TestimonialAdd} />
            <Route path="/testimonial/edit/:id" component={TestimonialEdit} />
          {/* Testimonial route end */}
          {/* Gold Bar route start */}
            <Route exact path="/goldbar/list" component={GoldBarList}/>
            <Route exact path="/goldbar/add" component={GoldBarAdd}/>
            <Route path="/goldbar/edit/:id" component={GoldBarEdit}/>
          {/* Gold Bar route end */}
          {/* Request's route start */}
            <Route exact path="/request" component={RequestList}/>
          {/* Request's route end */}
          {/* theme default routes */}
          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/typography" component={ Typography } />
          <Route path="/form-Elements/basic-elements" component={ BasicElements } />
          <Route path="/tables/basic-table" component={ BasicTable } />
          <Route path="/icons/mdi" component={ Mdi } />
          <Route path="/charts/chart-js" component={ ChartJs } />
          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;