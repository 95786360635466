import React, {useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
const Sidebar = ({location}) => {
  const mainSetting = useSelector(state=>state.setting.main)
  const [menu,setMenu] = useState({
    cmsMenuOpen:false,
    settingMenuOpen:false,
    investerMenuOpen:false,
    testimonialMenuOpen:false,
    goldBarMenuOpen:false
  })
  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  }
  useEffect(()=>{
    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  },[])
  const toggleMenuState = (menuState) => {
    if (menu[menuState]) {
      menu[menuState] =false
      setMenu({...menu});
    } else if(Object.keys(menu).length === 0) {
      menu[menuState] =true
      setMenu({...menu});
    } else {
      Object.keys(menu).forEach(i => {
        menu[i] =false
      });
      menu[menuState] =true
      setMenu({...menu});
    }
  }
  useEffect(()=>{
    onRouteChanged()
  },[location])

  const onRouteChanged = ()=> {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(menu).forEach(i => {
      menu[i]= false;
    });
    setMenu({...menu})

    const dropdownPaths = [
      {path:'/cms', state: 'cmsMenuOpen'},
      {path:'/setting', state: 'settingMenuOpen'},
      {path:'/investor', state: 'investerMenuOpen'},
      {path:'/testimonial', state: 'testimonialMenuOpen'},
      {path:'/goldbar', state: 'goldBarMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        menu[obj.state]= true
        setMenu({...menu})
      }
    }));
 
  }

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <Link className="sidebar-brand brand-logo" to="/"><img src={mainSetting.mainLogo} alt="logo" /></Link>
          <Link className="sidebar-brand brand-logo-mini" to="/"><img src={mainSetting.smallLogo} alt="logo" /></Link>
        </div>
        <ul className="nav">
          <li className={ isPathActive('/') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">User Manager</span>
          </li>
          <li className={ isPathActive('/investor') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ menu.investerMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('investerMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">Invester Manager</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menu.investerMenuOpen }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/investor/list') ? 'nav-link active' : 'nav-link' } to="/investor/list">Listing</Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/investor/add') ? 'nav-link active' : 'nav-link' } to="/investor/add">Add</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">Shop Manager</span>
          </li>
          <li className={ isPathActive('/goldbar') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ menu.goldBarMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('goldBarMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-coin"></i>
              </span>
              <span className="menu-title">Gold Bar</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menu.goldBarMenuOpen }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/goldbar/list') ? 'nav-link active' : 'nav-link' } to="/goldbar/list">Listing</Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/goldbar/add') ? 'nav-link active' : 'nav-link' } to="/goldbar/add">Add</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">Other Manager</span>
          </li>
          <li className={ isPathActive('/setting') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ menu.settingMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('settingMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-security"></i>
              </span>
              <span className="menu-title">Setting's</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menu.settingMenuOpen }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/setting/main') ? 'nav-link active' : 'nav-link' } to="/setting/main">Main</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className={ isPathActive('/cms') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ menu.cmsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('cmsMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-book-multiple"></i>
              </span>
              <span className="menu-title">CMS Pages</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menu.cmsMenuOpen }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/cms/list') ? 'nav-link active' : 'nav-link' } to="/cms/list">Listing</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className={ isPathActive('/testimonial') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ menu.testimonialMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('testimonialMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-book-multiple"></i>
              </span>
              <span className="menu-title">Testimonial Pages</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ menu.testimonialMenuOpen }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/testimonial/list') ? 'nav-link active' : 'nav-link' } to="/testimonial/list">Listing</Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/testimonial/add') ? 'nav-link active' : 'nav-link' } to="/testimonial/add">Add</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className={ isPathActive('/request') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/request">
              <span className="menu-icon"><i className="mdi mdi-comment-question-outline"></i></span>
              <span className="menu-title">Request's</span>
            </Link>
          </li>
        </ul>
      </nav>
    );

  

}

export default withRouter(Sidebar);