import React, {useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../reducers/authSlice';
import 'nprogress/nprogress.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getMainSetting } from '../reducers/settingSlice';

const App =({history,location})=> {
  const dispatch = useDispatch()
  const [isFullPageLayout,setIsFullPageLayout] = useState(false)
  const authUser = useSelector(state=>state.auth.loggedInUser)
  const mainSetting = useSelector(state=>state.setting.main)
  useEffect(()=>{
    if (!mainSetting._id) {
        dispatch(getMainSetting())
    }
  },[mainSetting])
  const onRouteChanged = () => {
    console.log("ROUTE CHANGED",location.pathname);
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login','/password/forgot','/password/reset'];
      if (fullPageLayoutRoutes.includes(location.pathname)) {
        setIsFullPageLayout(true)
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
      } else {
        setIsFullPageLayout(false)
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
  }
  // useEffect(()=>{
  //   onRouteChanged()
  // },[])
  useEffect(()=>{
    onRouteChanged()
  },[location])
  useEffect(()=>{
    const nonAuthRoute = ['/login','/password/forgot','/password/reset']
    if (localStorage.getItem('auth-token-giraffe') && !authUser.isLoading) {
      if(!nonAuthRoute.includes(location.pathname) && authUser.data._id===''){
        history.push('/login')
      }else if(nonAuthRoute.includes(location.pathname) && authUser.data._id!==''){
        history.push('/')
      }
    }else{
      if(localStorage.getItem('auth-token-giraffe')){
        dispatch(getProfile())
      }else{
        if(!nonAuthRoute.includes(location.pathname)){
          history.push('/login')
        }
      } 
    }
  },[authUser])
  let navbarComponent = !isFullPageLayout ? <Navbar authUser={authUser}/> : '';
  let sidebarComponent = !isFullPageLayout ? <Sidebar/> : '';
  let footerComponent = !isFullPageLayout ? <Footer/> : '';
  return (
    <div className="container-scroller">
      <ToastContainer
        position="top-right"
        // hideProgressBar={true}
        autoClose={3000}
        closeOnClick
        draggable
      />
      { sidebarComponent }
      <div className="container-fluid page-body-wrapper">
        { navbarComponent }
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes/>
          </div>
          { footerComponent }
        </div>
      </div>
    </div>
  );
}

export default withRouter(App)
