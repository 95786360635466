import { createSlice } from "@reduxjs/toolkit";
import {callApi} from '../Helper/helper'
import { apiFail,apiSuccess } from './mainSlice'
const userSlice = createSlice({
    name:"User",
    initialState:{
        userList:null,
        userEdit:{
            userId:"",
            name:'',
            email:'',
            phone_no:'',
            status:''
        },
        userDetail:null
    },
    reducers:{
        listing:(state,action)=>{
            state.userList=action.payload.data
        },
        edit:(state,action)=>{
            state.userEdit=action.payload.data
        },
        unSetEdit:(state,action)=>{
            state.userEdit={
                userId:"",
                name:'',
                email:'',
                phone_no:'',
                status:''
            }
        },
        detail:(state,action)=>{
            state.userDetail = action.payload.data
        },
        unSetDetail:(state,action)=>{
            state.userDetail = null
        }
    }
})
export default userSlice.reducer
const { listing , edit , unSetEdit , detail , unSetDetail } = userSlice.actions

export const getList = (page=1,filterData={}) => async dispatch =>{
    try {
        const res = await callApi('post',`/user/list?page=${page}`,filterData)
        dispatch(unSetEdit())
        dispatch(unSetDetail())
        dispatch(listing(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const addUser = (value) => async dispatch => {
    try {
        const res = await callApi('post','/user/add',value)
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const editUser = (value) => async dispatch => {
    try {
        const res = await callApi('post','/user/edit',value)
        dispatch(edit(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const updateUser = (value) => async dispatch => {
    try {
        const res = await callApi('post','/user/update',value)
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const getUserDetail = (value) => async dispatch => {
    try {
        const res = await callApi('post','/user/detail',value)
        dispatch(detail(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
};


