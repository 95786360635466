import { createSlice } from "@reduxjs/toolkit";
import {callApi} from '../Helper/helper'
import { apiFail,apiSuccess } from './mainSlice'
const shopSlice = createSlice({
    name:"shop",
    initialState:{
        goldBar:{
            isLoading:true,
            data:[]
        },
        goldBarDetail:{
            _id:"",
            weight:"",
            purity:"",
            status:""
        }
    },
    reducers:{
        goldBarList:(state,action) =>{
            state.goldBar={
                isLoading:false,
                data: action.payload.data
            }
        },
        setGoldBarDetail:(state,action) =>{
            state.goldBarDetail = action.payload.data
        },
        unSetGoldBarDetail:(state,action) =>{
            state.goldBarDetail= {
                _id:"",
                weight:"",
                purity:"",
                status:""
            }   
        }
    }
})
export default shopSlice.reducer
const {goldBarList, setGoldBarDetail, unSetGoldBarDetail} = shopSlice.actions
export const getGoldBarList = () => async dispatch => {
    try {
        let res = await callApi('post','/shop/gold/list',{})
        dispatch(unSetGoldBarDetail())
        dispatch(goldBarList(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const getGoldBarDetail = (value) => async dispatch => {
    try {
        let res = await callApi('post','/shop/gold/detail',value)
        dispatch(setGoldBarDetail(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const addGoldBar = (value) => async dispatch => {
    try {
        let res = await callApi('post','/shop/gold/add',value)
        dispatch(apiSuccess(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const updateGoldBar = (value) => async dispatch => {
    try {
        let res = await callApi('post','/shop/gold/update',value)
        dispatch(apiSuccess(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}