import { createSlice } from "@reduxjs/toolkit";
import {callApi} from '../Helper/helper'
import { apiFail,apiSuccess } from './mainSlice'
const authSlice = createSlice({
    name: 'users',
    initialState: {
        loggedInUser : {
            isLoading:true,
            data:{
                _id: "",
                name: "",
                email: "",
                phone_no: "",
                pro_image: "",
                role: []
            }
        },
    },
    reducers: {
        authProfile:(state,action)=>{
            state.loggedInUser = {
                isLoading:false,
                data:action.payload.data   
            }
        },
        logoutSuccess:(state,action)=>{
            state.loggedInUser = {
                isLoading:false,
                data:{
                    _id: "",
                    name: "",
                    email: "",
                    phone_no: "",
                    pro_image: "",
                    role: []
                }   
            }
        },
    },
  })
const { logoutSuccess,authProfile} = authSlice.actions
export default authSlice.reducer
  
export const login = (values) => async dispatch => {
    try {
        const res = await callApi('post','/auth/login',values)
        localStorage.setItem('auth-token-giraffe',res.data.data.token)
        delete res.data.data.token
        // dispatch(loginSuccess(res.data))
        dispatch(apiSuccess(res.data))
        dispatch(authProfile(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}
export const logout= () => async dispatch =>{
    localStorage.removeItem('auth-token-giraffe')
    dispatch(logoutSuccess());
}
export const getProfile = () => async dispatch =>{
    try {
        const res = await callApi('post','/auth/profile',{});
        dispatch(authProfile(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}
export const updateProfile=(values)=>async dispatch =>{
    try {
        const res = await callApi('post','/auth/update',values,{'Content-Type': 'multipart/form-data'})
        dispatch(authProfile(res.data))   
        dispatch(apiSuccess(res.data));
    } catch (e) {
        dispatch(apiFail(e))
    }
    
}
export const updatePassword=(values)=>async dispatch=>{
    try {
        const res = await callApi('post','/auth/updatePassword',values);
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}
export const forgetPassword = (value) => async dispatch => {
    try {
        let res = await callApi('post','/auth/forgetpassword',value)
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}
export const resetPassword = (value) => async dispatch => {
    try {
        let res = await callApi('post','/auth/resetpassword',value)
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}
  // {type : "posts/createPost", payload : {id : 123, title : "Hello World"}}