import React, { Component } from 'react'

export class Spinner extends Component {
  render() {
    return (
      <div className="cusloader-contaner">
            <div className="custom-loader">
                <div className="table-wrap">
                    <div className="align-wrap">
                        <div className="loader"></div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default Spinner
