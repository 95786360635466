import { configureStore } from '@reduxjs/toolkit'
import mainSlice from './reducers/mainSlice'
import authSlice from './reducers/authSlice'
import userSlice from './reducers/userSlice'
import requestSlice from './reducers/requestSlice'
import cmsSlice from './reducers/cmsSlice'
import settingSlice from './reducers/settingSlice'
import shopSlice from './reducers/shopSlice'


const reducer = {
  main:mainSlice,
  auth:authSlice,
  user:userSlice,
  request:requestSlice,
  cms:cmsSlice,
  setting:settingSlice,
  shop:shopSlice,
}

const store = configureStore({
  reducer
})

export default store