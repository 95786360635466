import { createSlice } from "@reduxjs/toolkit";
import {callApi} from '../Helper/helper'
import { apiFail,apiSuccess } from './mainSlice'
const requestSlice = createSlice({
    name:"request",
    initialState:{
        contact:{
            isLoading:true,
            data:[]
        }
    },
    reducers:{
        contactList:(state,action) =>{
            state.contact={
                isLoading:false,
                data: action.payload.data
            }
        },
    }
})
export default requestSlice.reducer
const {contactList} = requestSlice.actions
export const getContactList = () => async dispatch => {
    try {
        let res = await callApi('post','/request/contact',{})
        dispatch(contactList(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}


